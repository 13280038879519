<template>
  <div class="nadia-story-view">
    <button @click="goBack" class="back-button">Back to Home</button>
    <img src="@/assets/cyberpunk_scene.png" alt="Cyberpunk Scene" class="story-image" />
    <div class="story-content">
      <h1 class="story-title">Nadia's Journey</h1>
      <p v-for="(paragraph, index) in storyContent" :key="index" class="story-text">
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script>
import storyData from "@/assets/story.json";

export default {
  name: "NadiaStoryView",
  data() {
    return {
      storyContent: [],
    };
  },
  created() {
    this.storyContent = storyData.story;
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.nadia-story-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0d0f18;
  color: #f8f8f8;
  padding: 20px;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ff007f;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #e6006f;
}

.story-image {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.story-title {
  font-size: 2rem;
  color: #ff007f;
  margin-bottom: 10px;
}

.story-text {
  font-size: 1.2rem;
  color: #c0c0c0;
  margin-bottom: 15px;
  line-height: 1.5;
}
</style>
