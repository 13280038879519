<template>
  <div class="view">
    <button @click="goBack" class="back-button">Back to Home</button>
    <div class="content">
      <h1 class="number">Widok ID: {{ id }}</h1>
      <img :src="animalImage" alt="Animal Image" class="animal-image" />
      <p class="animal">{{ randomAnimal }}</p>
    </div>
  </div>
</template>

<script>
const animals = [
  { name: "CyberCat", image: "CyberCat.png" },
  { name: "NeonWolf", image: "NeonWolf.png" },
  { name: "MechaTiger", image: "MechaTiger.png" },
  { name: "QuantumFox", image: "QuantumFox.png" },
  { name: "HoloBear", image: "HoloBear.png" },
  { name: "PlasmaShark", image: "PlasmaShark.png" },
  { name: "NeuroEagle", image: "NeuroEagle.png" },
  { name: "NanoDragon", image: "NanoDragon.png" },
  { name: "SynthDeer", image: "SynthDeer.png" },
];

export default {
  props: ["id"], // Odbieranie ID jako prop
  data() {
    const animal = animals[this.id - 1] || animals[0]; // Get animal by ID
    return {
      randomAnimal: animal.name,
      animalImage: require(`@/assets/animals/${animal.image}`),
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.view {
  text-align: center;
  background-color: #0d0f18;
  color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ff007f;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #e6006f;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-size: 6rem;
  color: #ff007f;
}

.animal {
  font-size: 2rem;
  color: #00ffff;
}

.animal-image {
  max-width: 400px;
  margin: 20px 0;
  border: 5px solid #ff007f;
  border-radius: 10px;
}
</style>