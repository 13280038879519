<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
.app {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  background-color: #0d0f18;
  color: #f8f8f8;
  min-height: 100vh;
}
</style>