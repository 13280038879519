<template>
  <div class="home">
    <div class="image-container">
      <img src="@/assets/cityscape.png" alt="Cyberpunk Cityscape" class="cityscape-image" />
    </div>
    <div class="buttons-container">
      <button
        v-for="icon in icons"
        :key="icon.id"
        class="button"
        :style="{ backgroundColor: icon.color }"
        @click="navigateTo(icon.id)"
      >
        {{ icon.id }}
      </button>
    </div>

    <div class="story-button-container">
      <button class="story-button" @click="navigateToStory">
        Nadia's Story
      </button>
    </div>
    <div class="story-button-container">
      <button class="story-button" @click="navigateToCyberpunkUI">
        CyberpunkUI
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      icons: Array.from({ length: 9 }, (_, i) => ({
        id: i + 1,
        color: this.generateColor(i),
      })),
    };
  },
  methods: {
    navigateToStory() {
      this.$router.push("/nadia-story");
    },

    navigateToCyberpunkUI() {
      this.$router.push("/cyberpunk-ui");
    },

    navigateTo(id) {
      this.$router.push(`/view/${id}`);
    },
    generateColor(index) {
      const colors = [
        "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF",
        "#33FFF5", "#FF8C33", "#C0FF33", "#FF3333",
      ];
      return colors[index % colors.length];
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #f8f8f8;
}

.image-container {
  margin-bottom: 20px;
}

.cityscape-image {
  width: 100%;
  max-width: 1024px;
  border-radius: 10px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.button {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.button:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.story-button-container {
  margin-top: 30px;
}

.story-button {
  padding: 10px 20px;
  background-color: #ff007f;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.story-button:hover {
  background-color: #e6006f;
}
</style>